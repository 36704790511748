.table {
  :global {
    .ant-table-thead > tr > th {
      background-color : var(--primary-dark);
      color : white;
      padding: 10px 10px;
    }
    .ant-table-tbody > tr > td {
      padding: 10px 10px;
    }
  }
}

.row {
  &:hover {
    cursor: pointer;
  }
}
