@import '~antd/dist/antd.css';
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-Neue-Medium-Extended.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-Neue-UltraLight.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-Neue-W01-66-Medium-It.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-Neue-W01-77-BdCnObl.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-Neue-W01-87-HvCnObl.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/Helvetica-NeueLTW0693BlkExtObl.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/Helvetica-Neue-Font/LTW0697BlkCnObl.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/meiryo/meiryo.ttc") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/segoe-ui-4-cufonfonts/Segoe-UI.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/segoe-ui-4-cufonfonts/Segoe-UI-Bold-Italic.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/segoe-ui-4-cufonfonts/Segoe-UI-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Helvetica-Neue-Font";
  src: url("../assets/font/segoe-ui-4-cufonfonts/Segoe-UI-Italic.ttf") format("truetype");
  font-weight: normal;
}

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Ubuntu',
    sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px !important;
}
.emoji-mart-bar {
  display: none!important;
}
#cooperation-preview {
  position: relative;
  display: initial;
  border: none;
  border-radius: 2px;
}
.editorContainer {
  padding: 1em;
  margin: 1em;
}

.editors {
  border: 1px blue solid;
  padding: 1em;
  margin: 1em;
  font-family: 'Open Sans';
  font-size: 90%;
}
.text-right {
  text-align: right;
}
.ant-popover.emoji.ant-popover-placement-top  {
  .ant-popover-inner-content {
    padding: 0!important;
  }
}

.schema-modal {
  width: 560px!important;
  .ant-modal-body {
    padding: 8px;
  }
  .ant-modal-header {
    padding: 16px 12px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}
.sticker-modal {
  .ant-tabs-nav-wrap {
    background: #f0f0f0;
  }
  .ant-modal-body {
    padding: 0!important;
    .ant-tabs-ink-bar {
      background: #009d4b;
    }
    .ant-tabs-tabpane {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
      justify-content: center;
    }
    .ant-tabs-tab {
      margin: 0!important;
      padding: 0 5px!important;
      .anticon {
        font-size: 18px;
        display: flex;
        color: #5d5d5d;
        margin: 0 5px!important;
      }
      .ant-tabs-tab-btn {
        width: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        & > div {
          width: 40px;
          height: 40px;
          &:hover {
            img {
              width: 35px!important;
            }
          }
        }
        img {
          width: 35px;
        }
      }
    }
  }
}

.btn-condition:hover {
  fill: #C5771A;
}
.btn-action:hover {
  fill: #C5771A;
}

.invoice-message {
  .ant-tooltip-inner {
    padding: 2px 4px!important;
  }
}
@media (min-width: 340px) {
  .product-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .product-item {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 992px) {
  .product-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  .product-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}